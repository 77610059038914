
/*COULEURS*/
$light-contrast : #fafafa;
$primary-color : #992583;
$secondary-color: #65CCC5;
$text-dark : #3C3C3B;
$text-light : #A3A3A3;
$bg-contrast: #FFF;


/* CSS */
@import './vendor/_normalize';
@import './vendor/_animate';
@import './vendor/_slick';

@import './_header';
@import './_content';
@import './_footer';
@import './_accordeon';
@import './_custom';

/* FONT */
@font-face {
	font-family: "Bernadette";
	src: url("../fonts/bernadette.ttf");
}


_
/* DEFAULT */
* {
	box-sizing: border-box;
	outline: none;
}
body {
	background-color : #fff;
	color : $text-dark;
	font-family: 'M PLUS 1p', sans-serif;
	font-weight: 400;
	font-size: 16px;
}

section {
	// width: 100%;
	overflow: hidden;
	padding: 80px 0;
}
section.header {
	padding: 0;
	.slick-dots {
		display: none;
	}
}
section.grey,
.grey {
	background-color: $bg-contrast;
}

.content_V3 {
	display: flex
}
.demi_section {
	width: 50%;
	float: left;
	padding: 150px;
	box-sizing: border-box;
}

.full {
	width: 100%;
}

.container {
	margin: 0 auto;
	overflow: hidden;

}

.big_container {
	width: 75%;
	margin: 0 auto;
	position: relative;
	//overflow: hidden;

}
.row {
	max-width: inherit;
}
.container.columns {
	margin: 0 auto;
}

.demi {
	width: 50%;
	float: left;
    // height: 850px;
    position: relative;

    .btn {
    	margin: 0 50px;
    }
  }

  .demi_texte {
  	width: 45%;
  	max-width: 700px;
  	margin-left: 50px;
  }


  .link_play {
  	overflow: hidden;
  	display: block;
  	width: 50%;
  	height: 600px;
  	position: relative;

  	.play {
  		position: absolute;
  		overflow: hidden;
  		display: inline-block;
  		top: 50%;
  		left: 50%;
  		z-index: 50;
  		transform: translateX(-50%) translateY(-50%);
  		i {
  			color: $light-contrast;
  			font-size: 92px;
  			transition: all .3s linear;
  		}
  	}
  	&:hover .play i{
  		color: $secondary-color;
  	}
  	.demi_img {
  		position: absolute;
  		width: 100%;
  		height: 600px;
  	}

  }

  div,
  .section {
  	background-size: cover;
  	background-repeat: no-repeat;
  	background-position: center center;
  }


  .img_parallax {
  	background-attachment: fixed;
  }

  .img-full {
  	height: 700px;
  	background-image: url('../img/banner.jpg');
  	background-repeat: no-repeat;
  	background-size: cover;
  	background-position: center center;
  	margin-top: 100px;
  }


  /* STYLES DE TEXTES */
  .columns {
  	margin: 25px 0;
  }

  ul {
  	padding: 0;
  	margin: 0;
  }

  li {
  	list-style: none;
  }

  .liste {
  	li {
  		list-style: circle;
  		margin-left: 30px;
  		margin-top: 10px;
  	}
  }

  .h2 {
  	color: $text-dark;
  	font-size: 42px;
  	font-weight: 700;
  	margin-bottom: 2rem;
  	position: relative;
  	text-align: center;
  	font-family: "Bernadette";
  	font-weight: 100;
  	&::after {
  		content: "";
  		position: absolute;
  		display: block;
  		height: 4px;
  		width: 160px;
  		background: $primary-color;
  		left: 50%;
  		margin-top: 0;
  		transform: translateY(-20%) translateX(-50%);

  	}
  }
  .tiny_h2 {
  	font-size: 32px;
  }

  .subtitle_h2 {
  	color: $text-light;
  	font-size: 20px;
  	display: inline-block;
  	&::before {
  		content: "";
  		width: 30px;
  		height: 20px;
  		display: inline-block;
  		position: relative;
  		background: url('../img/before-title.svg') no-repeat;
  		top: 7px;
  		margin-right: 5px;
  	}
  }

  .h3 {
  	color: $primary-color;
  	text-transform: uppercase;
  	font-size: 24px;
  	margin: 10px 0;
  }

  .h4 {
  	font-weight: 500;
  	font-size: 20px;
  }


  .little_img {
  	width: 50%;
  }

  footer {
  	background: -webkit-linear-gradient(#D6B3CF 0%, #992583 100%);
  	background: -o-linear-gradient(#D6B3CF 0%, #992583 100%);
  	background: linear-gradient(#D6B3CF 0%, #992583 100%);
  	.h2 {
  		color: white;
  		margin: 0;
  		width: 100%;
  		text-align: left;

  		&::after {
  			content: none;
  		}
  	}
  	.subtitle_h2 {
  		color: white;
  		&::before {
  			content: none;
  		}
  	}
  	.fas, .far {
  		margin-right: 5px;
  		color: #A5D7D5;
  		display: inline;
  	}

  	.contact-infos{
  		margin: 30px 0;
  		flex-basis: 100%;
  		display: flex;
  		justify-content: space-between;
  		.column {
  			display: flex;
  			flex-direction: column;
  			justify-content: space-around;
  			width: auto;
  			.rdv {
  				margin-left: 1.5rem;
  			}
  		}

  	}
  }







  /* CALL TO ACTIONS */
  .ta-center {
  	text-align: center;
  }
  .btn {
	// text-align: center;
	padding: 15px 30px;
	display: inline-block;
	// width: 30%;
	// max-width: 300px;
	// min-height: 20px;
	margin: 30px auto;
	transition: all .3s linear;
	border-radius: 0%;
}

.btn-color {
	background-color: $secondary-color;
	color: $light-contrast;
	border: 1px solid $secondary-color;
	&:hover {
		background-color: transparent;
		color: $secondary-color;
		border: 1px solid $secondary-color;
	}
}

.btn-color-light {
	background-color: $secondary-color;
	color: $light-contrast;
	border: 1px solid $secondary-color;
	&:hover {
		background-color: transparent;
		color: $secondary-color;
		border: 1px solid $secondary-color;
	}
}

form .btn {
	margin: 0;
	border: none;
	background-color: #CA97C0;
	border: 1px solid #CA97C0;
	color:  $light-contrast;
	float: right;

	&:hover {
		background-color: transparent;
		border: 1px solid #CA97C0;
		color: $light-contrast;
		.fa {
			left: 15px;
		}
	}
	.fa {
		color: $light-contrast;
		position: relative;
		left: 10px;
		transition: all .1s linear;
	}
}

a {
	text-decoration: none;
	color: $primary-color;
	&:hover {
		color: $secondary-color;
	}
}

input, textarea {
	border-radius: 0;
}




/*404*/
.section_404 {
	text-align: center;
	height: 100vh;
	.container {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

	.h1 {
		font-size: 62px;
		margin-bottom: 10px;
	}

	.btn {
		background-color: $primary-color;
		color: $light-contrast;
		&:hover {
			background-color: rgba($primary-color, .7);
			color: $light-contrast;
			.fa {
				left: -7px;
				color: $light-contrast;
			}
		}
		.fa {
			color: $light-contrast;
			position: relative;
			left: -2px;
			transition: all .2s linear;
		}
	}
}





/* RESPONSIVE */


@media screen and (max-width: 1200px) {
	.demi_section {
		padding: 50px;
	}
}

@media screen and (max-width: 1442px) {
	.plugin_fb {
		// display: none;
	}
	footer {
		// .medium-8 {
		// 	width: 100%;
		// }
	}

}

@media screen and (max-width: 960px) {

	.big_container, .container {
		width: 90%;
		margin: 0 auto;
	}
	.demi {
		width: 100%;

	}

	.demi_texte {
		max-width: 100%;
		height: inherit;
	}
	.img-full {
		height: 450px;
		margin-top: 50px;
	}

}


@media screen and (max-width: 850px) {

	.menu {
		display: none;
	}
	.h2 {
		font-size: 32px;
	}
	.btn {
		width: 100%;
		max-width: 100%;
	}

}

@media screen and (max-width: 640px) {

	form .btn {
		float: none;
	}
	.download_V1 form input {
		width: 100%;

		margin-bottom: 15px;
	}
}

@media screen and (max-width: 768px) {
	footer {
		background: -webkit-linear-gradient(#D6B3CF 0%, #992583 100%);
		background: -o-linear-gradient(#D6B3CF 0%, #992583 100%);
		background: linear-gradient(#D6B3CF 0%, #992583 100%);
		.h2 {
			color: white;
			margin: 0;
			width: 100%;
			text-align: left;

			&::after {
				content: none;
			}
		}
		.subtitle_h2 {
			color: white;
			&::before {
				content: none;
			}
		}
		.fas, .far {
			margin-right: 5px;
			color: #A5D7D5;
			display: inline;
		}

		.contact-infos{
			margin: 30px 0;
			flex-basis: 100%;
			display: flex;
			flex-direction: column;
			justify-content: initial;
			align-items: stretch;

			.info {
				flex-basis: initial;
				float: left;
				margin: 0 auto 1rem auto;
				color: #DCE7E6;
				.fa-phone,
				.fa-map-marker {
					position: relative;
				}
				a {
					display: inline-block;

				}
			}
			.info_hour {
				order: 1
			}
			.info_rdv {
				order: 2;
				padding-left: 0;
			}
			.info_adress {
				order: 3;
				margin-left: auto;
				margin-right: auto;
				div {
					float: initial;
					margin: 0 auto 0 auto;
					p {
						text-align: initial;
					}
				}
			}
			.info_phone {
				order: 4;
				div {
					display: flex;
					justify-content: initial;
				}
			}
			.info_tva {
				order: 6;
				div {
					float: initial;
				}
			}
			.info_mail {
				order: 5;
				div {
					display: initial;
				}
			}
		}
	}
}

@media screen and (max-width: 414px) {
	p {
		font-size: 14px;
		margin: 0;
	}
	section {
		padding: 20px 0;
	}
	.btn {
		margin: 10px auto;
	}
}


/*MAJ 20/06/2024*/
.header .full{
	position: relative;
	z-index: 0;
}

.header .full:before{
	content: "";
	// background-color: rgba(0,0,0,0.3);
	background: linear-gradient(to bottom, rgba(0,0,0,0.5) 33%,  rgba(0,0,0,0) 100%);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.header .banner_container{
	position: relative;
	z-index: 1;
}


.header .ban_logo{
	margin-bottom: 30px !important;
}

.header .baseline{
	color: #fff !important;
}

.btn_container{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}


.btn_item a{
	min-width: 245px;
	text-align: center;
}

.btn_item_1{
	width: 50%;
	text-align: right;
	padding: 0 10px;
}
.btn_item_2{
	width: 50%;
	text-align: left;
	padding: 0 10px;
}
.btn_item_3{
	width: 50%;
	text-align: center;
	padding: 0 10px;
}

@media screen and (max-width: 640px) {
	.btn_item_1{
		width: 100%;
		text-align: center;
		padding: 0 10px;
	}
	.btn_item_2{
		width: 100%;
		text-align: center;
		padding: 0 10px;
	}
	.btn_item_3{
		width: 100%;
		text-align: center;
		padding: 0 10px;
	}
	.btn_item a{
	max-width: 300px;
	text-align: center;
}
}