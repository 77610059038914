
.section_accordeon {
  .columns:first-child {
    margin-bottom: 0;
  }
}

.accordeon {
  // margin: 2rem;
}

.accordeon_item {
  background: $secondary-color;
  // border-radius: 5px;
  margin-bottom: 0.5rem;
}

.active > .accordeon_title {
  // border-radius: 5px 5px 0 0;

  &:after {
    transform: rotate(90deg);
    transition: all ease .2s;
    top: 20%
  }
}

.accordeon_title {
  position: relative;
  cursor: pointer;
  padding: 10px 15px;
  margin-bottom: 0;
  color: #fff;
  font-weight: bold;
  background: $primary-color;
  // border-radius: 5px;
    
  &:after {
    position: absolute;

    right: 10px;
    width: 0;
    height: 0;
    background-image: url('./../img/angle-right-white.svg');
    // border-style: solid;
    // border-width: 10px 5px 0 5px;
    // border-color: white transparent transparent transparent;
    content: '';
    width: 13px;
    height: 30px;
    background-repeat: no-repeat;
    text-align: center;
    transition: all ease .2s;
    top: 50%;
    transform: translateY(-50%);
  }
}
  
.accordeon_content {
  padding: 10px 15px;
  box-shadow: inset 0 -3px $primary-color;
  // border-radius: 5px;
}
