.product_V1 {
  .product3 {
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .columns {
      width: 30%;
      padding: 0 0 7.5rem 0;
      //margin: 1rem auto;
      border: solid 1px rgba(153,37,131,0.2);
      box-shadow: 0 0 24px 0 rgba(0,0,0,0.2);
      position: relative;
      background: white;

      p {
        margin: 5px 1rem ;
      }
      h3 {
        margin: 10px 1rem  5px 1rem;
        font-size: 1rem;
        font-size: 16px;
      }
      img {
        width: 100%;
      }
      p {
        color: $text-dark;
        font-size: 16px; 
      }
      .info_price {
        background: #FDF9FB;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;

        color: $primary-color;
        text-align: center;
        padding: .6rem;
        p {
          color: $primary-color;
          font-size: .8rem;
          margin: 0;
          .price {
            font-size: 2.5rem;
            display: block;
          }
        }
      }
    }

  }
  .btn-color-light {
    width: 300px;
    background-color: transparent;
    color: $primary-color;
    border: 1px solid $primary-color;
    &:hover {
      background-color: $primary-color;
      color: $light-contrast;
      border: 1px solid $primary-color;
    }
  }
  .btn-color {
    width: 300px;
    background-color: $primary-color;
    color: $light-contrast;
    border: 1px solid $primary-color;
    &:hover {
      background-color: transparent;
      color: $primary-color;
      border: 1px solid $primary-color;
    }
  }
}
.gallery {
  background: -webkit-linear-gradient(rgba(209,234,235,0.10) 0%, rgba(164,209,210,0.30) 100%);
  background: -o-linear-gradient(rgba(209,234,235,0.10) 0%, rgba(164,209,210,0.30) 100%);
  background: linear-gradient(rgba(209,234,235,0.10) 0%, rgba(164,209,210,0.30) 100%);
  h2 {
  }
  .card {
    text-align: center;
    //height: 755px;
    //top: 65px;
    width: 300px;
    padding: 1rem 0 7rem 0;
    background: white;

    }
    .h4 {
      color: $primary-color;
      font-size: 3rem;
      margin: 0;
      font-family: "Bernadette";
      font-weight: 100;
    }
    p {
      margin: 0;
    }
    .list_soin {
      .list_item {
        line-height: 2.2em;
        width: 100%;
        font-size: 16px;
        &:nth-child(odd) {
          background: #FDF9FB;
        }
        p {
          max-width: 95%;
          margin: 0 auto;
        }
      }
      .true {
        color: #44263F;
      }
      .false {
        color: #CBC0C9;
        text-decoration: line-through;
      }

    }
  }
  .card_girl {
    border: solid 1px $primary-color;
    .h3 {
      color: #44263F;
      font-size: .8rem;
      &:before {
        display: block;
        content: "";
        background-image: url(../img/girl.svg);
        background-repeat: no-repeat;
        height: 30px;
        background-position: center;
        margin-bottom: .2rem;
      }
  }

  .info_price_girl {
    background: #D192C3;
    position: absolute;
    left: 0; bottom: 0;
    width: 100%;
    text-align: center;
    padding: .6rem;
      p {
        color: white;
        font-size: .8rem;
        margin: 0;
          .price {
            font-size: 2.5rem;
            display: block;
          }
      }
  }
  .info_price_boy {
    background: #A5D7D5;
    position: absolute;
    left: 0; bottom: 0;
    width: 100%;
    text-align: center;
    padding: .6rem;
    p {
        color: white;
        font-size: .8rem;
        margin: 0;
          .price {
            font-size: 2.5rem;
            display: block;
          }
      }
  }
  .slick-arrow {

  }
}
.card_boy {
    border: solid 1px #A5D7D5;
    .h3 {
      color: #44263F;
      font-size: .8rem;
      &:before {
        display: block;
        content: "";
        background-image: url(../img/man.svg);
        background-repeat: no-repeat;
        height: 30px;
        background-position: center;
        margin-bottom: .2rem;
      }
  }
    .h4 {
      color: #A5D7D5;
    }
    .list_soin {
      .list_item {
        line-height: 2.2em;
        width: 100%;
        font-size: 16px;
        &:nth-child(odd) {
          background: #F6FAF9;

        }
        p {
          max-width: 95%;
          margin: 0 auto;
        }
      }
      .true {
        color: #44263F;
      }
      .false {
        color: #CBC0C9;
        text-decoration: line-through;
      }

    }
      .info_price_boy {
    background: #A5D7D5;
    position: absolute;
    left: 0; bottom: 0;
    width: 100%;
    text-align: center;
    padding: .6rem;
    p {
        color: white;
        font-size: .8rem;
        margin: 0;
          .price {
            font-size: 2.5rem;
            display: block;
          }
      }
  }
  }
.slick-list,
.slick-track {
  display: flex;
}

.extra {
  display: flex;
  justify-content: space-between;
  padding: .3rem;
  .extra_cadeau, .extra_maquillage {
    background: #FCF8FB;
    flex-basis: 50%;
    margin: .3rem;
    text-align: center;
    padding: 2rem 1rem 1rem 1rem;
    .extra_svg {
      height: 38px;
    }
    h2 {
      font-size: 2rem;
      margin-top: 0rem;
      margin-bottom: 1rem;
    }
    p {
      max-width: 600px;
      margin: 0 auto;
    }
    .btn-color-light {
    width: 300px;
    background-color: transparent;
    color: $primary-color;
    border: 1px solid $primary-color;
    &:hover {
      background-color: $primary-color;
      color: $light-contrast;
      border: 1px solid $primary-color;
    }
  }
  }
}
.gallery_V3-slider {

  .draggable {
    //height: 200px;
    .slick-track {
      //height: 100px;
      img {
        padding: 0;
        margin:0;
        filter: grayscale(80%);
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .extra {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: .3rem;
  .extra_cadeau, .extra_maquillage {
    background: #FCF8FB;
    margin: .3rem;
    text-align: center;
    padding: 1rem;
    .extra_svg {
      height: 38px;
    }
    .btn-color-light {
    width: 300px;
    background-color: transparent;
    color: $primary-color;
    border: 1px solid $primary-color;
    &:hover {
      background-color: $primary-color;
      color: $light-contrast;
      border: 1px solid $primary-color;
    }
  }
  }
}
}


@media screen and (max-width: 1024px) and (min-width: 665px) {
  .product_V1 {
  .product3 {
    .columns {
      width: 49%;
      padding: 0 0 7.5rem;
      margin: 25px 0;
    }
  }
}
}

@media screen and (max-width: 664px) {
  .product_V1 {
  .product3 {
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .columns {
      width: 99%;
      padding: 0 0 6rem 0;
      margin: 1rem auto;
      border: solid 1px rgba(153,37,131,0.2);
      box-shadow: 0 0 24px 0 rgba(0,0,0,0.2);
      position: relative;
      background: white;

      h3, p {
        margin: 1rem;
      }
      h3 {
        font-size: 1rem;
        font-size: 16px;
      }
      p {
        color: $text-dark;
        font-size: 16px;
      }
      .info_price {
        background: #FDF9FB;
        position: absolute;
        left: 0; bottom: 0;
        width: 100%;
        color: $primary-color;
        text-align: center;
        padding: .6rem;
        p {
          color: $primary-color;
          font-size: .8rem;
          margin: 0;
          .price {
            font-size: 2.5rem;
            display: block;
          }
        }
      }
    }
  }
}
}

@media screen and (max-width: 414px) {
  .gallery {
    .card {
      height: 520px;
      ul {
        li{
          p {
            line-height: 2em;
          }
        }
      }

    }
  }
}


.btn-nav{
    padding: 2px 10px !important;
    // display: inline-block;
    // margin: 30px auto;
    // transition: all .3s linear;
    
    background-color: #992583;
    color: #fafafa;
    border: 1px solid #992583;
    &:hover{
      background-color: transparent;
      color: #992583;
      border: 1px solid #992583;
    }
     
  }

