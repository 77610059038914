

/* ABOUT V1*/
.about_V1 {
	position: relative;

	.big_container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
	.demi_texte {
		float: right;
	}
}




/* ABOUT V2*/
.about_V2 {
	text-align: center;
	img {
		height: 150px;
	}
	p {
		max-width: 600px;
		margin: 0 auto;
	}
	h2 {

	}
}




/* ABOUT V3 LEFT */
.about_V3_left {
	padding: 0;
	position: relative;

	.big_container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
	.demi_texte {
		float: right;
	}
}



/* ABOUT V3 RiGHT */
.about_V3_right {
	padding: 0;
	position: relative;

	.big_container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
	}
	.demi_texte {
		float: left;
	}


	.link_play {
		overflow: hidden;
		display: block;
		height: 600px;
		position: relative;
		float: right;
		z-index: 1;
	}
}




/* ABOUT V4 */
.about_V4 {}

.img_rolled {
	max-width: 300px;
	height: auto;
	float: left;
	margin: 0px 25px 15px 0px;
}

.product2 {
	.columns:nth-child(2n-1) {
		clear: both;
	}
}
.product3 {
	.columns:nth-child(3n-2) {
		clear: both;
	}
}
.product4 {
	.columns:nth-child(4n-3) {
		clear: both;
	}
}



.content_V2 {
	.span {
		margin-bottom: 10px;
	}
	.h3 {
		margin-top: 50px;
	}
}



/* TABLEAUX */

table {
	width: 100%;
	text-align: center;
	margin: 30px 0px 50px 0px;
	th {
		font-weight: 500;
		color: $primary-color;
	}
	tr {
		height: 50px;
		&:nth-child(2n-1) {
			background: rgba($secondary-color, .5)
		}
	}
	.left {
		text-align: left;
		position: relative;
		left: 30px;
	}
}

.grid_V2 {
	.part {
		margin-bottom: 50px;
	}
}

.grid_V2 {}

.grid_V3 {}


.products_V1 {}








/*DOWNLOAD , NEWSLETTER , PDF*/

.download {
	text-align: center;
	padding-top: 50px;
	span{
		margin-bottom: 0px;
		&:before {
			background: none;
		}
	}
}

.download_V1 {
	form {
		width: 80%;
		margin: 50px auto 0 auto;
		display: flex;
		input {
			width: 75%;
			border: 1px solid $secondary-color;
			padding: 15px;
			float: left;
			display: inline-block;
			flex-grow: 1;
		}
		button {
			padding: 15px 40px;
			color: $bg-contrast;
			margin-bottom: 0;
			width: initial;
		}
	}
}

.download_V2 {
	form {
		width: 70%;
		margin: 50px auto 0 auto;
		input {
			width: 100%;
			border: 1px solid $secondary-color;
			padding: 15px;
			display: block;
		}
		button {
			padding: 15px 40px;
			display: inline-block;
			color: $bg-contrast;
			display: inline-block;
			color: #f5f5f5;
			max-width: 300px;
			border-radius: 0%;
			margin: 15px auto;
			float: none;
		}
	}
}

.download_V3 {
	.container {
		border-bottom: 1px solid $text-light;
		padding-bottom: 50px;
	}

}




.partner_V1 {
    .ligne_partner {

        text-align: center;
        margin-bottom: 30px;
        a {
            cursor: pointer;
            overflow: hidden;
            width: 100%;
            height: 100%;
            // margin-right: 7%;
            &:hover .img_partner {
                -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
                filter: grayscale(0%);
            }
        }

        .img_partner {
            /*filtre gris */
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            opacity: .7;

            width: 20%;
            margin: 25px 10px;
            max-height: 100px;
            transition: all .3s linear;

        }

    }

}


/*TESTIMONIALS*/

/*TESTI V1*/
.testi_fixed {
	padding: 2rem 0;
	background-repeat: no-repeat;
	// background-attachment: fixed;
	background-position: top 25% center;
	background-size: cover;
	font-weight: 300;
	margin: 50px 0;

	p, a, li {
		font-style: italic;
		font-size: 18px;
	}
}
.testimonials_V1 {

	.h2, p, li, a {
		color: $bg-contrast;
	}
}

.testimonials_V2 {
	color: $bg-contrast;
	text-align: center;
	.testi_img {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		margin-bottom: 25px;
	}
	small{
		font-size: 18px;
	}
}

.testimonials_V3 {
	color: $text-dark;
	text-align: center;
	font-size: 22px;
	margin: 0;
	.slick-list {
		//height: 180px;
	}

	small{
		font-size: 18px;
		color: $primary-color;
	}

	a {
		color: #A5D7D5;
		display: block;
	}
}


/*GALLERY */
.gallery {
	.slick-prev,
	.slick-next,
	.slick-arrows {
	    //width: 25px;
	    z-index: 50;
	    left: -25px;
	    height: 27px;
	    bottom: 50%;
	    //transform: translateY(100%);
	    background-image: url('../img/arrow-color-left.svg');
	}
	.slick-next {
	    background-image: url('../img/arrow-color-right.svg');
	    left: 100%;
	}

	// .slick-dots {
	// 	position: absolute;
 //    bottom: -25px;
 //    display: block;
 //    width: 100%;
 //    padding: 0;
 //    margin: 0;
 //    list-style: none;
 //    text-align: center;;
	// }

}

/* GALLERY 1*/
.gallery_V1-slider {
	margin: 0 auto;
	transition: all .3s linear;
	padding-bottom: 75px;
	position: relative;

	.slick-track {
		//height: 600px;
	}
	.slick-slide {
		//height: 250px;
		width: 300px;
		margin: 0 20px;
		position: relative;
		//top: 25px;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		transition: all .3s linear;
	}
	.slick-current {
		//transform: scale(1.2);
	}
}


/* GALLERY 2*/
.gallery_V2-slider-for {
	margin: 0 auto;
	transition: all .3s linear;
	padding-bottom: 15px;
	position: relative;

	.slick-slide {
		height: 600px;
		width: 300px;
		margin: 0 5px;
		position: relative;
		top: 25px;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		transition: all .3s linear;
	}
}

.gallery_V2-slider-nav {
	margin: 0 auto;
	transition: all .3s linear;
	padding-bottom: 75px;
	position: relative;

	.slick-slide {
		height: 150px;
		width: 22%;
		margin: 0 5px;
		position: relative;
		top: 25px;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		transition: all .3s linear;
	}
	.slick-dots {
		display: none;
	}

}

/*PICTO*/

.atouts {
	background: -webkit-linear-gradient(255deg, #EFFFFE 0%, #D9F1F0 100%);
	background: -o-linear-gradient(255deg, #EFFFFE 0%, #D9F1F0 100%);
	background: linear-gradient(345deg, #EFFFFE 0%, #D9F1F0 100%);
	.picto {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		padding: 0;
			li {
				width: 120px;
				margin: 2rem 0;
				text-align: center;
				span {
					padding: 0;

				}
				img {
				height: 55px;
				display: block;
				margin: 0 auto 1.6rem auto;
				}
			}
		}
	}


@media screen and (max-width: 1024px) {
	.product2 {
		.columns:nth-child(2n-1) {
			clear: both;
		}
	}
	.product3 {
		.columns:nth-child(3n-2) {
			clear: none;
		}
		.columns:nth-child(2n-1) {
			clear: both;
		}
	}
	.product4 {
		.columns:nth-child(4n-3) {
			clear: none;
		}
		.columns:nth-child(2n-1) {
			clear: both;
		}
	}
	.atouts {
		.picto {
			li {
				flex-basis: 33%;
			}
		}
	}
}

@media screen and (max-width: 960px) {

	/* ABOUT V1*/
	.about_V1 {
		position: relative;

		.big_container {
			position: relative;
			top: inherit;
			left: inherit;
			transform: none;
		}
		.demi {
			width: 100%;
		}
		.demi_texte {
			height: inherit;
		}

	}
	.demi_texte {
		float: none;
		margin-left: 0;
	}



	.about_V3_left {


		.big_container {
			position: relative;
			top: inherit;
			left: inherit;
			transform: none;
		}
	}
	.about_V3_left,
	.about_V3_right {
		padding: 50px 0;
	}
}
@media screen and (max-width: 850px) {
	.content_V3 {
		.demi_section {
			width: 100%;
			float: none;
			padding: 50px 5%;

		}
	}
	.gallery_V2-slider-for {
		.slick-slide {
			height: 400px;
		}
	}
}

@media screen and (max-width: 640px) {
	.img_rolled {
		max-width: 100%;
		height: auto;
		float: none;
		margin: 0;
	}
	th {
		font-size: 14px;
	}
	td {
		font-size: 13px;
	}

	.partner_V1 .ligne_partner  .img_partner {
        width: 25%;
	}


	.gallery_V2-slider-for {
		.slick-slide {
			height: 350px;
		}
	}
	.gallery_V2-slider-nav {
		.slick-slide {
			display: none;
		}
	}

}

@media screen and (max-width: 520px) {

	.atouts {
		.picto {
			li {
				flex-basis: 50%;
			}
		}
	}


}

@media screen and (max-width: 768px) {

	.gallery {
		.slick-prev,
		.slick-next,
		.slick-arrows {
	    width: 25px;
	    z-index: 50;
	    left: -25px;
	    height: 0px;
	    bottom: 58%;
	    transform: translateY(100%);
	    background-image: url('../img/arrow-color-left.svg');
		}
	}


}



@media screen and (max-width: 450px) {

	.partner_V1 .ligne_partner  .img_partner {
        width: 35%;
	}


}

