

/*NAVIGATIONS*/
nav {
    position: fixed;
    z-index: 99999;
    background-color: $bg-contrast;
    width: 100%;
    overflow: hidden;

    a {
        color: $text-dark;
        transition: all .3s linear;
        &:hover,
        &:focus,
        &:active {
            color: $primary-color;
        }
    }
    .logo {
        float: left;
        color: $primary-color;
        background-image: url('../img/logo.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center center;
        display: inline;
        height: 40px;
        text-indent: -9999px;
        margin: 0;
        float: left;
    }
    .tiny_logo img {
        height: 40px;
        display: inline;
        float: left;
    }
}





/* NAV 1*/
.nav_V1 {
    .nav_langue {
        float: right;
        padding: 5px 2px;
        float: right;
        background-color: $primary-color;
        font-weight: 300;

        li{
            display: inline-block;
            margin: 0px;
            margin:0px 10px;

        }
        a {
            color: $light-contrast;
            &:hover {
                color: $secondary-color;
            }
        }
    }
    .container_menu {
        padding: 15px 0;
        .logo {
            height: auto;
            width: 180px;
        }
        .menu {
            float: right;
            position: relative;
            right: 0;
            padding: 15px 0;
            li{
                display: inline-block;
                margin: 0px;
                margin-left: 25px;
            }
        }
    }

}

.menu .button, .menu a {
    padding: 0rem;
}


/* NAV V2*/
.nav_V2 {
    .container_menu {
        padding: 15px 0;
        text-align: center;
        .menu {
            display: inline-block;
            margin-top: 0px;
            text-align: center;
            li{
                display: inline-block;
                margin: 0px;
                margin: 0px 25px;
                vertical-align: middle;
            }
        }
    }
}


/* NAV V3*/
.nav_V3 {
    .container_menu {
        padding: 15px 0;
        text-align: center;
        display: flex;
        align-items: center;
        .h1_hide {
            position: absolute;
            left: -9999px;
            top: auto;
            width: 1px;
            height: 1px;
            overflow: hidden;
        }
        .logo_desktop {
            height: 50px;
        }
        .logo_mobile {
            height: 0px;
        }
        .menu {
            display: inline-block;
            margin-top: 11px;
            float: right;
            li{
                display: inline-block;
                margin: 0 10px;
                vertical-align: middle;
                .link {
                    border-left: solid 1px $primary-color;
                    padding-left: .5rem;
                }
            }
        }
        .nav_social {
            display: inline-block;
            li {
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
}








/*HEADERS*/
.slick-dots {
    margin: 10px 0 0;
    padding: 0;
    text-align: center;
    li {
        display: inline;
        margin: .5rem;
        button {
            background: $primary-color;
            border-radius: 50%;
            height: 8px;
            width: 8px;
            display: inline-block;
            overflow: hidden;
            text-indent: -9999px;
            position: relative;
        }
    }
    .slick-active {
        button {
            background: #D192C3;
        }

    }

}

.slick-prev,
    .slick-next,
    .slick-arrows {
        display: inline-block;
        color: red;
        float: left;
        position: absolute;
        width: 20px;
        height: 0px;
        text-indent: -9999999px;
        background-color: transparent;
        background-image: url('../img/arrow-white-left.svg');
        background-repeat: no-repeat;
        background-size: 100%;
        opacity: .7;
        transition: all .3s linear;
        outline: none;
    }
    .slick-next {
        background-image: url('../img/arrow-white-right.svg');
    }
    .slick-prev:hover,
    .slick-prev:active,
    .slick-prev:focus,
    .slick-next:hover,
    .slick-next:active,
    .slick-next:focus,
    .slick-arrows:hover,
    .slick-arrows:active,
    .slick-arrows:focus {
        opacity: 1;
}


/*HEADER V1*/
.header {
}

.header_V1 {
    .full {
        width: 100%;
        height: 950px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        overflow: hidden;

        /*filtre gris pour que le texte soit lisible sur n'importe quelle photo*/
        -webkit-filter: grayscale(40%); /* Safari 6.0 - 9.0 */
        filter: grayscale(0%);
        position: relative;
        .filter {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

    }
    .bandeau {
        background-color: rgba($primary-color, .8);
        padding: 30px 150px 30px 100px;
        max-width: 70%;
        bottom: 100px;
        position: absolute;
    }
    .h1 {
        color: $light-contrast;

        margin-bottom: 0px;
        font-size: 48px;
        display: block;
        width: 100%;
    }
    .baseline {
        color: $light-contrast;
        font-size: 18px;
        display: inline-block;
        padding: 5px;
        max-width: 600px;
        margin: 0 auto;

    }

    .slick-prev,
    .slick-next,
    .slick-arrows {
        bottom: 30px;
        right: 60px;
    }
    .slick-next {
        right: 30px;
    }

}



/*HEADER V2*/
.header_V2 {
    .full {
        top: 70px;
        position: relative;
        width: 100%;
        padding-top: 11rem;
        padding-bottom: 16rem;
        min-height: 700px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center 0%;
        overflow: hidden;

        /*filtre gris pour que le texte soit lisible sur n'importe quelle photo*/
        -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
        filter: grayscale(0%);

        .h1, .baseline, .cta_rdv{
            text-align: center;
            // text-shadow: 0px 2px $text-dark;
        }
        .ban_logo {
            // color: $light-contrast;
            // margin-top: 350px;
            width: 250px;

            margin: 8px auto 8px auto;
            // font-size: 84px;
            display: block;
        }
        .ban_fournisseur {
            width: 250px;
            margin: 8px auto 24px auto;
            display: block;
        }
        .baseline {
            color: $text-dark;
            font-size: 18px;
            margin: 8px auto 24px auto;
            padding: 0;
            max-width: 600px;
            margin: 0 auto;
        }
        .cta_rdv {
            width: 300px;
            display: block;
            font-size: 20px;
            background: #A5D7D5;
            border: solid 1px #A5D7D5;
            &:hover {
                border: solid 1px #A5D7D5;
                color: #A5D7D5 ;
                background: transparent;
            }

        }
    }
    .slick-prev,
    .slick-next,
    .slick-arrows {
        position: absolute;
        top: 700px;
        left: calc(50% - 15px);;
        z-index: 50;
        transform: translateX(-50%);
        background-image: url('../img/arrow-white-left.svg');
    }
    .slick-next {
        background-image: url('../img/arrow-white-right.svg');
        left: calc(50% + 15px);
        transform: translateX(-50%);
    }

}



/*HEADER V3*/
.header_V3 {

    .demi {
        width: 50%;
        float: left;
        height: 850px;
        overflow: hidden;
    }
    .slick-slide {

        width: 50%;
        height: 850px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
    .h1,
    .baseline {
        width: 80%;
        margin-left: 50px;
    }
    .h1 {
        color: $text-dark;
        margin-top: 350px;
        margin-bottom: 0px;
        font-size: 48px;
        display: block;
    }
    .baseline {
        color: $primary-color;
        font-size: 20px;
        display: inline-block;
    }
    .slick-prev,
    .slick-next,
    .slick-arrows {
        bottom: 50px;
        left: 50px;
        float: left;
        background-image: url('../img/arrow-color-left.svg');
    }
    .slick-next {
        background-image: url('../img/arrow-color-right.svg');
        left: 80px;
    }
}





/* RESPONSIVE */


@media screen and (max-width: 1300px) {
    .header_V2 {
        .full {
            .filter {

            }
        }
    }
    .nav_V3 {
        .container_menu {
            .logo_desktop {
                display: none;
            }
            .logo_mobile {
                height: 50px;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .header {
        height: inherit;
    }
    .menu {
        font-size: 13px;
    }
    .nav_V1 .container_menu .logo {
        width: 130px;
    }

    .header_V1 .bandeau {
        max-width: 100%;
        padding: 30px 50px 30px 50px;
    }

    .header_V2 .full .h1 {
        font-size: 64px;
    }
    .header_V3{
        .demi {
            width: 100%;
            height: 400px;
        }
        .h1 {
            margin-top: 50px;
        }
        .demi-slider-nav {
            height: 400px;
        }
    }
    .nav_V3 {
        .container_menu {
        .menu {
            font-size: 16px;
        }
        }
    }
}


@media screen and (max-width: 850px) {
    .nav_V3 {
        .container_menu {
            .menu {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    .header_V2 {
        .full {
            .h1 {
                font-size: 42px;
                margin-left: 5%;
                margin-right: 5%;
            }
            .baseline {
                font-size: 16px;
                margin: 8px auto 8px auto;
            }
        }
    }

    .header_V2 .full , .header_V3 .demi {
        .h1 {
            font-size: 28px;
             margin: 8px auto 8px auto;
        }
        .baseline {
            font-size: 16px;
            margin: 8px auto 8px auto;
        }
    }


    .menu .link {
        display: none
    }
}
@media screen and (max-width: 850px) {
    .header_V2 {
        .full {
            .cta_rdv {
                width: 250px;
                font-size: 16px;
            }
        }
    }
}
