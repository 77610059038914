/* MAPS V1 */
.maps {
	width: 100%;
	height: 350px;
}
.maps img {
	max-width: inherit !important;
}







.footer {
	color: $light-contrast;
	padding-top: 50px;
	p {
		display: inline-block;
		margin: 0;
	}
	.h2 {
		margin-top: 0;
	}
	small {
		flex-basis: 100%;
		width: 100%;
		text-align: center;
		color: $light-contrast;
		display: inline-block;
		margin-top: 50px;
		padding: 15px 0;
		border-top: 1px solid rgba($secondary-color, .5);
		a {
			color: $secondary-color;
		}
		.fas, .far {
			float: inherit;
		}
	}
	a {
		color: $light-contrast;
		&:hover {
			color: $secondary-color;
		}
	}
	form {
		label,
	    input,
	    textarea {
	        width: 100%;

	        color: $light-contrast;
	        margin: 5px 0;
	        box-sizing: border-box;
	    }
	    input, textarea {
	        padding: 5px 10px;
	        border: solid 2px white;
	        margin-bottom: 15px;
	        color: $text-dark;
	        outline: none;
	    }
	    textarea {
	        color: $text-light;
			resize: none;
	    }
	    :-moz-placeholder { /* Firefox 18- */
	    	color: $text-light;
	    	font-weight: 300;
	    }
	}
	.columns {
		margin: 0;
		//flex-basis: 50%;
	}
	.btn {
		// width:inherit;
	}
}

/* FOOTER V2 */
.footer_V2 {
	overflow: hidden;
	padding: 85px 0 0px 0;
	.container {
		flex-basis: 100%
	}
	form {
		margin: 25px 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.name, .surname, .mail, .phone {
			flex-basis: 48%;
		}
		.object, .message, .form_btn {
			flex-basis: 100%;
		}
	}
	textarea {
		height: 100px;
	}
	.info {
		margin: 4px 0;
	}
	.info-footer-right {
		margin-top: O;
		width: 33%;
		color: $light-contrast;

		a {
			color: $light-contrast;
		}
		.contact-infos {
			display: block;
		}
		.contact-infos {
			padding-bottom: 20px;
			border-bottom: 1px solid $secondary-color;
		}
		.contact-infos:nth-child(2n-1) {
			padding-bottom: 0;
			border-bottom: none;
		}
	}
	.info .fas, .far {
		display: inline-block;
	    float: left;
	    margin-right: 10px;
	    /* top: -50%; */
	    //position: relative;
	    transform: translateY(20%);
	}
.container_flex {
	display: flex;
	flex-wrap: wrap;
}
}

/* FOOTER V3 */
.footer_V3 {
	overflow: hidden;
	padding: 50px 0 30px 0;

	textarea {
		height: 150px;
	}

	.bandeau_footer {
		width: 100%;
		border-top: none;
		overflow: hidden;
		margin-top: 50px;
	}

	.info-footer {
		display: block;
		width: 100%;
		overflow: hidden;
		color: $light-contrast;
		.info {
			text-align: center;
			&.info_social {
				width: 100%;
				font-size: 20px;
			}
			.fa {
				display: block;
				margin-bottom: 10px;
			}
			p {
				margin: 0;
			}
		}
		.fa.top {
			display: block;
    		margin-bottom: 10px;
		}

	}
	.info_social {
		margin-top: 25px;
		text-align: center;
		margin: 0 auto;
		padding: 30px 0 15px 0;
	}

	small {
		border-top: none;
		margin-top: 0px;
	}
}




.footer form.form_inner_button {
	width: 100%;
	margin: 28px 0;
	box-sizing: border-box;

	input {
		border: none;
		color: $light-contrast;
		margin: 0;
		padding: 5px 10px;
		margin-bottom: 0px;
		color: $text-dark;
		outline: none;
		width: 75%;
		padding: 15px ;
	}
	button {
		width: 25%;
		display: inline;
		height: 49px;
	}
}

/* FOOTER V4 */
.footer_V4 {
	.info {
		margin: 10px 0;
	}
	.column_left {
		margin-top: 85px;
	}
	.news {
		margin-top: 50px;
	}
}

/* FOOTER V5 */
.footer_V5 {
	.info {
		text-align: center;
		.fa {
			display: block;
			margin-bottom: 10px;
		}
	}

}


/* FOOTER V6 */
.footer_V6 {
	.info {
		margin: 30px 0;
	}
	.info_social {
		display: inline;
		.info {
			float: left;
			margin-right: 10px;
		}
		.fa {
			font-size: 24px;
		}
	}
}





@media screen and (max-width: 1024px) {
	.product2 {
		.columns:nth-child(2n-1) {
			clear: both;
		}
	}
	.product3 {
		.columns:nth-child(3n-2) {
			clear: none;
		}
		.columns:nth-child(2n-1) {
			clear: both;
		}
	}
	.product4 {
		.columns:nth-child(4n-3) {
			clear: none;
		}
		.columns:nth-child(2n-1) {
			clear: both;
		}
	}
}

@media screen and (max-width: 960px) {

}

@media screen and (max-width: 850px) {
	.footer{
		.columns {
			flex-basis: 100%;
		}
		
		.plugin_fb {
			flex-basis: 100%;
			display: flex;
			justify-content: center;
		}

		.contact-infos {
			margin-top: 25px;
			i ,
			.footer_V2 .info .fas,
			.footer_V2 .far{
				position: relative;
				margin: 10px auto 5px auto !important;
				text-align: center;
				left: inherit;
				display: block !important;
				float: none !important;
				transform: inherit !important;
			}

			p {
				text-align: center;
				padding-left: 0;
				margin-left: 0 !important;
			}
		}
	}
}

@media screen and (max-width: 640px) {
	.footer_V1, .footer_V3 {
		.info-footer .info {
			margin: 15px 0;
		}
	}
	.footer_V5 .info {
		margin: 15px 0;
	}
	.footer_V2 .info-footer-right {
		margin: 20px 0;
	}

	.footer form.form_inner_button {
		input {
			width: 100%;
		}
		button {
			width: 25%;
		    display: block;
		    height: 49px;
		    margin: 15px auto;
		}
	}
	.footer_V4 .column_left {
		margin-top: 15px;
	}

}

@media screen and (max-width: 450px) {
	.footer_V2 {
	overflow: hidden;
	padding: 50px 0 30px 0;
	.container {
		flex-basis: 100%
	}
	form {
		margin: 50px 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.name, .surname, .mail, .phone {
			flex-basis: 100%;
		}
		.object, .message, .form_btn {
			flex-basis: 100%;
		}
		button {
			width: 100%;
		}
	}
}
}